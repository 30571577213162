<template>
  <section id="knowledge-base">
    <v-card
      flat
      class="mb-7"
    >
      <v-img
        src="@/assets/images/banners/profile.png"
        height="250"
        position="right"
      />
      <v-card-text class="position-relative">
        <v-avatar
          size="60"
          color="white"
          class="avatar-center"
        >
          <v-img :src="$auth.user.picture"></v-img>
        </v-avatar>
        <!-- Title, Subtitle & Action Button -->
        <div class="d-flex justify-space-between flex-wrap pt-12">
          <div class="me-2 mb-2">
            <v-card-title class="pt-0 px-0">
              {{ $store.state.User.user.firstName }} {{ $store.state.User.user.lastName }}
            </v-card-title>
            <v-card-subtitle class="text-xs pa-0">
              {{ $auth.user.email }}
            </v-card-subtitle>
            <v-card-subtitle class="text-xs pa-0">
              {{ userProfile.ocp }}
            </v-card-subtitle>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <!-- Do you want to add your picture? Simply add a picture to your email on gravatar -->
    <!-- <div
      class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100"
    >
      https://en.gravatar.com/
    </div> -->

    <v-row class="kb-search-content-info match-height">
      <v-col
        v-for="(pharmacy, _id) in userPharmacies"
        :key="_id"
        class="kb-search-content"
      >
        <v-card
          color="text-center cursor-pointer"
          flat
          class="mb-7"
        >
          <div class="kb-character-wrapper">
            <v-img
              max-width="150"
              contain
              class="mx-auto align-center justify-center"
              src="@/assets/images/icons/pharmacies.png"
            ></v-img>
          </div>
          <v-card-title class="justify-center pb-3">
            {{ pharmacy.name }}
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <div class="me-auto pe-4">
              <p class="d-flex justify-start align-center mb-6">
                <v-icon color="primary">
                  {{ icons.mdiPhone }}
                </v-icon>
                <span class="ms-3 text-sm-left">{{ pharmacy.phone }}</span>
              </p>

              <p class="d-flex justify-start align-center mb-6">
                <v-icon color="primary">
                  {{ icons.mdiFax }}
                </v-icon>
                <span class="ms-3 text-sm-left">{{ pharmacy.fax }}</span>
              </p>

              <p class="d-flex justify-start align-center mb-6">
                <v-icon color="primary">
                  {{ icons.mdiMapMarker }}
                </v-icon>
                <span class="ms-3 text-sm-left">{{ `${pharmacy.address.streetNumber} ${pharmacy.address.streetName}, ${pharmacy.address.city}, ${pharmacy.address.state}, ${pharmacy.address.code}` }}</span>
              </p>

              <p class="d-flex justify-start align-center mb-6">
                <v-icon color="primary">
                  {{ icons.mdiEmailCheck }}
                </v-icon>
                <span class="ms-3 text-sm-left">{{ pharmacy.email }}</span>
              </p>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiPhone, mdiFax, mdiEmailCheck, mdiMapMarker } from '@mdi/js'
import { userService } from '@/render/api/zarya'

export default {
  computed: {
    ...mapGetters(['PharmacyProfile']),
  },
  data() {
    return {
      userPharmacies: '',
      userProfile: this.$store.getters.UserProfile,
      icons: {
        mdiPhone,
        mdiFax,
        mdiEmailCheck,
        mdiMapMarker,
      },
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const token = await this.$auth.getTokenSilently()
      const id = this.$auth.user.sub.replace('auth0|', '')
      this.userPharmacies = await userService.getUserPharmacies(
        {
          id,
        },
        token,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';
// @import '@core/preset/preset/pages/Profile.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
